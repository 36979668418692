import ImagemView from "../model/ImagemView";
import React, {
    Dispatch,
    forwardRef,
    RefObject,
    SetStateAction,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import {Type} from "../model/ItemType";
import {Column} from "primereact/column";
import {DataTable, DataTablePageEvent} from "primereact/datatable";
import PanelService from "../service/PanelService";
import {Toast} from "primereact/toast";
import {ItemFolder} from "../model/ItemFolder";
import {Button} from "primereact/button";
import ImageBox from "./ImageBox";

interface PropsDataListImage {
    folder: String,
    type: Type,
    dispatchLoading: Dispatch<SetStateAction<boolean>>;
    toast: RefObject<Toast>;
    filter: string;
    dispatchValue: Dispatch<SetStateAction<Array<ImagemView>>>;
}

interface LazyTableState {
    first: number;
    rows: number;
    page: number|undefined;
}

const DataListImage = forwardRef((props: PropsDataListImage, ref) => {
    const [selectedItems, setSelectedItems] = useState<Array<ImagemView>>([]);
    const [data, setData] = useState<Array<ImagemView>>();
    const [totalSize, setTotalSize] = useState<number>(0);

    const [cursor, setCursor] = useState<Array<string>>(["", ""]);

    const [lazyState, setlazyState] = useState<LazyTableState>({
        first: 0,
        rows: 10,
        page: 1,
    });

    const [loading, setLoading] = useState<boolean>(false);

    const fetchData = async () => {
        if(lazyState.page === 1){
            setCursor(["", ""]);
        }

        const data = await PanelService
            .get(
                props.type.alias,
                props.folder === ItemFolder.Safe,
                lazyState.rows,
                cursor[(lazyState.page ?? 1)],
                props.filter
            )
            .get(props.dispatchLoading, props.toast);

        return { items: data?.items, tokens: data?.tokens, totalRecords: data?.total };
    }

    const onLoadData = async () => {
        setLoading(true);
        const {items, tokens, totalRecords} = await fetchData();
        if(items){
            setData(items)
        }

        if(tokens){
            setCursor(tokens)
        }

        if (totalRecords){
            setTotalSize(totalRecords);
        }
        setLoading(false);
    }

    useImperativeHandle(ref, () => ({
        onRefresh: () => onLoadData()
    }));


    useEffect(() => {
        onLoadData()
    }, [props.filter, lazyState]);


    const onPage = (event: DataTablePageEvent) => {
        setlazyState({
            first: event.first,
            rows: event.rows,
            page: event.page,
        });
    };
    const url = `${process.env.REACT_APP_API_ENDPOINT}/finder/${props.type.alias}?${props.type.filter}=`;

    function open(url: string) {
        window.open(url, '_blank')?.focus();
    }

    async function copy(value: string) {
        await navigator.clipboard.writeText(value);
        props.toast.current?.show({
            severity: "success",
            detail: `Copiado ${value}`,
        });
    }

    function remove(item: ImagemView){
        if(window.confirm(`Deseja deletar ${item.name}?`)){
            PanelService
                .remove(item.name, item.source)
                .run(props.dispatchLoading, props.toast, `Arquivo ${item.name} deletado!`)
                .then((_) => onLoadData())
        }
    }

    const nameBody = (item: ImagemView) => {
        return <div>
            <Button className="h-2rem" label={item.name} onClick={() => {open(`https://www.google.com/search?q=${item.name}`)}}/>
        </div>;
    };

    const actionBody = (item: ImagemView) => {
        return <div>
            <Button className="h-2rem w-2rem" icon="pi pi-clipboard" onClick={() => {copy(item.name)}}/>
            <Button className="h-2rem w-2rem ml-4" icon="pi pi-google" severity="success" onClick={() => open(item.detail)}/>
            <Button className="h-2rem w-2rem ml-4" icon="pi pi-trash" severity="danger" onClick={() => remove(item)}/>
        </div>;
    };

    const imagemBody = (item: ImagemView) => {
        return <ImageBox url={`${url}${item.name}`} name={item.name}/>;
    }

    const header = (
        <div className="flex flex-column align-items-center">
            <span className="text-900 font-bold">Pasta: {props.folder}</span>
        </div>
    );

    return (
        <>
            <DataTable
                paginatorClassName="p-0"
                header={header}
                first={lazyState.first}
                lazy
                onPage={onPage}
                loading={loading}
                selectionMode="multiple"
                value={data} className="w-6"
                selection={selectedItems}
                onSelectionChange={(e) => {
                    setSelectedItems(e.value);
                    props.dispatchValue(e.value);
                }}
                paginator rows={lazyState.rows} rowsPerPageOptions={[5, 10, 25, 50, 100]}
                totalRecords={totalSize}
                sortField="name" sortOrder={-1}
                size="small"
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column header="Image" body={imagemBody}></Column>
                <Column header="Name" field="name" body={nameBody}></Column>
                <Column header="Ações" body={actionBody}></Column>
            </DataTable>
        </>
    )

});

export default DataListImage;
