import React, { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import {Image} from "primereact/image";

interface PropsImageBox {
    url: string;
    name: string;
}

export default function ImageBox(props: PropsImageBox) {
    const op = useRef<OverlayPanel>(null);

    return (
        <div>
            <Image src={props.url} alt={props.name} height="30" onClick={(e) => {
                op?.current?.toggle(e)
            }}/>
            <OverlayPanel ref={op}>
                <img src={props.url} alt={props.name}></img>
            </OverlayPanel>
        </div>
    );

}
