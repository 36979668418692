import React, {ReactElement, useRef, useState} from "react"
import {InputText} from "primereact/inputtext"
import {Button} from "primereact/button"
import logo from '../../logo.png'
import LoginController from "./service/LoginController";
import {Toast} from "primereact/toast";
import {Loading} from "../../widget/loading/Loading";
import {useNavigate} from "react-router-dom";

export function LoginPage(): ReactElement {
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const controller = React.useCallback(
        () => new LoginController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );
    const navigate = useNavigate();

    function onLogin() {
        controller().login(email, password)
            .then((result) => {
                if (result) {
                    navigate(`/panel`, {replace: false});
                }
            })
    }

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className="flex justify-content-center align-items-center" style={{height: "100vh"}}>
                <div className="surface-card p-4 shadow-2 border-round lg:w-6">
                    <div className="text-center mb-5">
                        <img src={logo} alt="hyper" height={120} className="mb-3"/>
                        <div className="text-900 text-3xl font-medium mb-3">V360 Imagens</div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                        <InputText value={email} onChange={(e) => setEmail(e.target.value)}
                                   id="email" type="text" placeholder="Email address" className="w-full mb-3"/>

                        <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                        <InputText value={password} onChange={(e) => setPassword(e.target.value)}
                                   type="password" placeholder="Password" className="w-full mb-3"/>

                        <Button label="Logar" icon="pi pi-user" className="w-full" onClick={() => onLogin()}/>
                    </div>
                </div>
            </div>
        </>
    )

}
