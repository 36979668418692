import {ReactElement, useEffect, useRef, useState} from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import {Toast} from "primereact/toast";
import {Loading} from "../../widget/loading/Loading";
import ItemType from "./model/ItemType";
import AbaAdmin from "./admin/AbaAdmin";
import TabContent from "./widget/TabContent";
import AdminService from "./admin/service/AdminService";

export function PanelPage(): ReactElement {
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const [link, setLink] = useState("");

    useEffect(() => {
        AdminService
            .getInfo()
            .get(setIsResponseLoading, toast)
            .then((data) => setLink(data?.content ?? ""));
    }, []);

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div>
                <TabView panelContainerClassName="p-2">
                    <TabPanel header="Product" headerClassName="p-0">
                        <TabContent type={ItemType.Product} dispatchLoading={setIsResponseLoading} toast={toast}/>
                    </TabPanel>
                    <TabPanel header="Brand" headerClassName="p-0">
                        <TabContent type={ItemType.Brand} dispatchLoading={setIsResponseLoading} toast={toast}/>
                    </TabPanel>
                    <TabPanel header="Supplier" headerClassName="p-0">
                        <TabContent type={ItemType.Supplier} dispatchLoading={setIsResponseLoading} toast={toast}/>
                    </TabPanel>
                    <TabPanel header="Establishment" headerClassName="p-0">
                        <TabContent type={ItemType.Establishment} dispatchLoading={setIsResponseLoading} toast={toast}/>
                    </TabPanel>
                    <TabPanel header="Admin" headerClassName="p-0">
                        <AbaAdmin dispatchLoading={setIsResponseLoading} toast={toast} link={link}/>
                    </TabPanel>
                </TabView>
            </div>
        </>
    )

}
