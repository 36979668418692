
import React, {ReactElement, useRef, useState} from 'react';
import { InputText } from 'primereact/inputtext';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {
    FileUpload, FileUploadBeforeSendEvent,
    FileUploadHeaderTemplateOptions,
    FileUploadSelectEvent,
    FileUploadUploadEvent, ItemTemplateOptions
} from "primereact/fileupload";
import {Toast} from "primereact/toast";
import {ProgressBar} from "primereact/progressbar";
import {Tag} from "primereact/tag";
import {Tooltip} from "primereact/tooltip";
import {Type} from "../model/ItemType";
import LocalStorage from "../../../../core/localstorage/LocalStorage";

interface PropsMenuPanel {
    onSearchClick: (value: string) => void;
    onAfterUpload: () => void;
    type: Type;
}

export default function MenuPanel(props: PropsMenuPanel): ReactElement {
    const [filter, setFilter] = useState<string | null | undefined>();
    const [visible, setVisible] = useState<boolean>(false);
    const toast = useRef<Toast>(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef<FileUpload>(null);

    const onTemplateSelect = (e: FileUploadSelectEvent) => {
        let _totalSize = totalSize;
        let files = e.files;

        for (let i = 0; i < files.length; i++) {
            _totalSize += files[i].size || 0;
        }

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e: FileUploadUploadEvent) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
        props.onAfterUpload();
        setFilter("");
        setVisible(false);
    };

    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / (1024 * 50);
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 50 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as any;
        return (
            <div className="flex align-items-center flex-wrap p-0">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} height={50} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };

    const onBeforeSend = (event: FileUploadBeforeSendEvent) => {
        event.xhr.setRequestHeader('Authorization', LocalStorage.getAccessToken());
        return event;
    }

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const url = `${process.env.REACT_APP_API_ENDPOINT}/admin/imagem/upload/${props.type.alias}`;

    const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter') {
            props.onSearchClick(filter ?? "")
        }
    }

    return (
        <>
            <Dialog header="Enviar Arquivos" visible={visible} style={{width: '50vw'}} onHide={() => {
                if (!visible) return;
                setVisible(false);
            }}>
                <div>
                    <Toast ref={toast}></Toast>

                    <Tooltip target=".custom-choose-btn" content="Choose" position="bottom"/>
                    <Tooltip target=".custom-upload-btn" content="Upload" position="bottom"/>
                    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom"/>

                    <FileUpload ref={fileUploadRef}
                                name="file"
                                url={url}
                                multiple
                                accept="image/*"
                                maxFileSize={1000000}
                                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear}
                                onClear={onTemplateClear}
                                headerTemplate={headerTemplate} itemTemplate={itemTemplate}
                                emptyTemplate={emptyTemplate}
                                chooseOptions={chooseOptions} uploadOptions={uploadOptions}
                                cancelOptions={cancelOptions} onBeforeSend={onBeforeSend}/>
                </div>
            </Dialog>
            <div className="flex overflow-hidden h-2rem mb-1">
                <div
                    className="flex-none flex align-items-center justify-content-center h-2rem">
                    <Button label="Buscar" className="ml-2 h-2rem" onClick={() => props.onSearchClick(filter ?? "")}/>
                </div>

                <div
                    className="flex-grow-1 flex align-items-center justify-content-center h-2rem">
                    <InputText
                        value={filter} onChange={(e) => setFilter(e.target.value)}
                        placeholder="Ean/Marca/Nome" type="text" className="w-full ml-2 mr-2 h-2rem"
                        onKeyDown={onEnter}
                    />
                </div>
                <div
                    className="flex-none flex align-items-center justify-content-center h-2rem">
                    <Button label="Enviar" className="ml-2 h-2rem" onClick={() => setVisible(true)}/>
                </div>
            </div>
        </>
    )
}
