import {AxiosResponse} from "axios";
import {Dispatch, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";

class ApiValue<T> {
    request: Promise<AxiosResponse>;

    constructor(request: Promise<AxiosResponse>) {
        this.request = request;
    }

    async get(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ): Promise<T|undefined|null> {
        try {
            dispatchLoading(true);
            const response = await this.request;
            if (response.status === 200) {
                return response.data;
            }
        }catch (e) {
            console.error(e);
            toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            dispatchLoading(false);
        }

        return undefined;
    }

}

export default ApiValue;
