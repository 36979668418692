import axios, {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from "axios"
import LocalStorage from "../localstorage/LocalStorage";
import LocalKey from "../localstorage/LocalKey";

const Api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        "Content-Type": "application/json",
    },
});

Api.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {

        config.headers[LocalKey.Authorization] = LocalStorage.getAccessToken()

        return config
    },
    (error: AxiosError) => {
        return Promise.reject(error)
    }
)

Api.interceptors.response.use(
    (response: AxiosResponse) => {
        return response
    },
    async (error: AxiosError) => {
        const originalRequest = error.config as InternalAxiosRequestConfig & { _retry: boolean }

        if (error.response?.status === 401 && !originalRequest._retry) {

            originalRequest._retry = true;

            try {

                const refreshToken = LocalStorage.getRefreshToken();

                const { data } = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/refresh`, { refreshToken })

                LocalStorage.setToken(data)

                originalRequest.headers[LocalKey.Authorization] = LocalStorage.getAccessToken()

                return Api(originalRequest);

            } catch (refreshError) {

                return Promise.reject(refreshError);

            }

        }
        return Promise.reject(error);
    }
)

export default Api
