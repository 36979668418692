import {AxiosResponse} from "axios";
import {Dispatch, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";

class ApiRequest {
    request: Promise<AxiosResponse>;

    constructor(request: Promise<AxiosResponse>) {
        this.request = request;
    }

    async run(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>,
        success: string,
    ) : Promise<boolean> {
        try {
            dispatchLoading(true);
            const response = await this.request;
            if (response.status > 199 && response.status < 300) {
                toast.current?.show({
                    severity: "success",
                    detail: success,
                });
                return true;
            }
        }catch (e) {
            console.error(e);
            toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            dispatchLoading(false);
        }
        return false;
    }


}

export default ApiRequest;
