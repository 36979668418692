import Api from "../../../../core/api/Api";
import ItemMove from "../model/ItemMove";
import ApiValue from "../../../../core/api/ApiValue";
import ItemListViewForm from "../model/ItemListViewForm";
import ApiRequest from "../../../../core/api/ApiRequest";

function get(
    type: string,
    safe: boolean,
    size: number,
    page: string,
    filter: string,
): ApiValue<ItemListViewForm> {
    return new ApiValue<ItemListViewForm>(Api.get(`/admin/imagem`, {params: {type, safe, size, page, filter}}));
}

function move(
    items: Array<ItemMove>,
): ApiRequest {
    return new ApiRequest(Api.post(`/admin/imagem/move`, {items}));
}

function remove(
    key: string,
    path: string,
): ApiRequest {
    return new ApiRequest(Api.post(`/admin/imagem/remove`, {key, path}));
}

const PanelService = {
    get,
    move,
    remove,
}

export default PanelService;
