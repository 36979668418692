import React, { ReactElement } from "react";
import { ProgressBar } from "primereact/progressbar";
import styles from "./Loading.module.sass"

interface LoadingProp {
  isLoading?: boolean | null;
}

export function Loading(props: LoadingProp): ReactElement {
  if (props.isLoading === null) {
    return <></>;
  }

  if (!props.isLoading) {
    return <></>;
  }

  return (
    <>
      <div className={styles.Loading}>
        <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
      </div>
    </>
  );
}
