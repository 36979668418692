import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {LoginPage} from "./app/page/login/LoginPage";
import RoutesEnum from "./app/route/RoutesEnum";


//theme
import "primereact/resources/themes/lara-light-cyan/theme.css";

//core
import "primereact/resources/primereact.min.css";

//blocks
import "primeflex/primeflex.css";

//Icons
import 'primeicons/primeicons.css';

import {PanelPage} from "./app/page/panel/PanelPage";

function App(): React.ReactElement {
  return (
      <>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<LoginPage/>}/>
              <Route path={RoutesEnum.Login} element={<LoginPage/>}/>
              <Route path={RoutesEnum.Panel} element={<PanelPage/>}/>
          </Routes>
        </BrowserRouter>
      </>
  );
}

export default App;
