import React, {Dispatch, ReactElement, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import CreateUser from "./widget/CreateUser";
import UpdateUser from "./widget/UpdateUser";
import AdminService from "./service/AdminService";

interface PropsAbaAdmin {
    dispatchLoading: Dispatch<SetStateAction<boolean>>;
    toast: RefObject<Toast>;
    link: string;
}

export default function AbaAdmin (props: PropsAbaAdmin): ReactElement {

    const onClick = () => {
        window.open(props.link, '_blank')?.focus();
    }

    const cleanCache = () => {
        AdminService
            .cleanCache()
            .run(props.dispatchLoading, props.toast, "Cache limpo com sucesso!!");
    }

    return (
        <>
            <div className="flex flex-column">
                <div
                    className="flex align-items-center justify-content-center  border-round m-2">
                    <div className="flex flex-row flex-wrap">
                        <div
                            className="flex align-items-center justify-content-center border-round m-2">
                            <CreateUser dispatchLoading={props.dispatchLoading} toast={props.toast}/>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center border-round m-2">
                            <UpdateUser dispatchLoading={props.dispatchLoading} toast={props.toast}/>
                        </div>
                    </div>
                </div>
                <div
                    className="flex align-items-center justify-content-center h-4rem bg-gray-200 font-bold border-round m-2">
                    <Button label="Abrir Bucket" severity="success" icon="pi pi-google" onClick={onClick}/>
                    <Button className="ml-5" label="Limpar Cache" severity="danger" icon="pi pi-eraser" onClick={cleanCache}/>
                </div>
            </div>
        </>
    );

}
