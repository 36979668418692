import AuthenticationRequest from "../model/AuthenticationRequest";
import ApiValue from "../../../../core/api/ApiValue";
import AuthenticationResponse from "../model/AuthenticationResponse";
import Api from "../../../../core/api/Api";
import RefreshTokenRequest from "../model/RefreshTokenRequest";
import RefreshTokenResponse from "../model/RefreshTokenResponse";


function auth(
    body: AuthenticationRequest
): ApiValue<AuthenticationResponse> {
    return new ApiValue<AuthenticationResponse>(
        Api.post(`/auth/login`, body)
    );
}

function refresh(
    body: RefreshTokenRequest
): ApiValue<RefreshTokenResponse> {
    return new ApiValue(
        Api.post(`/auth/refresh`, body)
    );
}

const LoginService = {
    auth,
    refresh,
}

export default LoginService;
