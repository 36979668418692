
import React, {
    useState,
    Dispatch,
    SetStateAction,
    RefObject,
    useRef,
    forwardRef,
    useImperativeHandle
} from 'react';
import {Toast} from "primereact/toast";
import ImagemView from "./model/ImagemView";
import MenuMove from "./widget/MenuMove";
import DataListImage from "./widget/DataListImage";
import {ItemFolder} from "./model/ItemFolder";
import {Type} from "./model/ItemType";
import PanelService from "./service/PanelService";
import { ProgressSpinner } from 'primereact/progressspinner';
import {Dialog} from "primereact/dialog";

interface ListPageProps {
    type: Type;
    dispatchLoading: Dispatch<SetStateAction<boolean>>;
    toast: RefObject<Toast>;
    filter: string;
}

const ListPage = forwardRef((props: ListPageProps, ref) => {
    const [quarantine, setQuarantine] = useState<Array<ImagemView>>([]);
    const [safe, setSafe] = useState<Array<ImagemView>>([]);
    const [visible, setVisible] = useState(false);

    const refListSafe = useRef<{onRefresh: () => void}>()
    const refListQuarantine = useRef<{onRefresh: () => void}>()

    const castToMove = (e: ImagemView) => {
        return {
            key: e.name,
            source: e.source,
            destination: e.destination,
        };
    }

    const onSent = (_: any) => {
        setVisible(false);
        refListSafe.current?.onRefresh();
        refListQuarantine.current?.onRefresh();
    }

    const toSafe = () => {
        setVisible(true)
        PanelService
            .move(quarantine.map(castToMove))
            .run(props.dispatchLoading,
                props.toast,
                "Arquivos enviados para a pasta Safe")
            .then(onSent)
    }

    const toQuarantine = () => {
        setVisible(true)
        PanelService
            .move(safe.map(castToMove))
            .run(props.dispatchLoading,
                props.toast,
                "Arquivos enviados para a pasta Quarantine")
            .then(onSent)
    }

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            refListQuarantine.current?.onRefresh();
            refListSafe.current?.onRefresh();
        }
    }));

    return (
        <div className="flex flex-row align-items-start gap-1 ">
            <Dialog
                header="Enviado"
                visible={visible}
                onHide={() => setVisible(false)}
                content={({hide}) => (
                    <div className="flex flex-column px-8 py-5 align-items-center gap-4 bg-white border-round-md">
                        <div className="font-bold text-2xl">Enviando</div>
                        <ProgressSpinner />
                    </div>
                )}
            >
            </Dialog>
            <DataListImage
                ref={refListQuarantine}
                filter={props.filter}
                dispatchValue={setQuarantine}
                dispatchLoading={props.dispatchLoading}
                toast={props.toast}
                type={props.type}
                folder={ItemFolder.Quarantine}
            ></DataListImage>
            <MenuMove toQuarantine={toQuarantine} toSafe={toSafe}/>
            <DataListImage
                ref={refListSafe}
                filter={props.filter}
                dispatchValue={setSafe}
                dispatchLoading={props.dispatchLoading}
                toast={props.toast}
                type={props.type}
                folder={ItemFolder.Safe}
            ></DataListImage>
        </div>
    )
});


export default ListPage;
