import LocalKey from "./LocalKey";

function getRefreshToken(): string {
    return `${LocalKey.Bearer} ${localStorage.getItem(LocalKey.RefreshToken)}`
}

function getAccessToken(): string {
    return `${LocalKey.Bearer} ${localStorage.getItem(LocalKey.AccessToken)}`
}

function setAccessToken(accessToken: string): void {
    localStorage.setItem(LocalKey.AccessToken, accessToken)
}

function setRefreshToken(refreshToken: string): void {
    localStorage.setItem(LocalKey.RefreshToken, refreshToken)
}

function setToken(token: { accessToken: string; refreshToken: string; }): void {
    setAccessToken(token.accessToken)
    setRefreshToken(token.refreshToken)
}

function setEmail(email: string): void {
    localStorage.setItem(LocalKey.Email, email)
}

function getEmail(): string {
    return localStorage.getItem(LocalKey.Email) ?? ''
}

function logout(): void {
    localStorage.removeItem(LocalKey.AccessToken)
    localStorage.removeItem(LocalKey.RefreshToken)
    localStorage.removeItem(LocalKey.Email)
}

const LocalStorage = {
    getRefreshToken,
    getAccessToken,
    setToken,
    setEmail,
    getEmail,
    logout,
}

export default LocalStorage

