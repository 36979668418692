import ApiValue from "../../../../../core/api/ApiValue";
import Api from "../../../../../core/api/Api";
import ApiRequest from "../../../../../core/api/ApiRequest";
import BucketInfo from "../model/BucketInfo";
import AuthenticationRequest from "../../../login/model/AuthenticationRequest";

function getInfo(): ApiValue<BucketInfo> {
    return new ApiValue<BucketInfo>(Api.get(`/admin/imagem/bucket`));
}

function create(
    request: AuthenticationRequest,
): ApiRequest {
    return new ApiRequest(Api.post(`/user/create`, request));
}

function update(
    request: AuthenticationRequest,
): ApiRequest {
    return new ApiRequest(Api.post(`/user/update`, request));
}

function cleanCache(): ApiRequest {
    return new ApiRequest(Api.post(`/admin/imagem/clean/cache`));
}

const AdminService = {
    getInfo,
    create,
    update,
    cleanCache
}

export default AdminService;
