export class Type {
    filter: string;
    alias: string;

    constructor(filter: string, alias: string) {
        this.filter = filter;
        this.alias = alias;
    }
}

const Product = new Type('ean', 'product');

const Brand = new Type('brand_name', 'brand');

const Supplier = new Type('supplier_name', 'supplier');

const Establishment = new Type('establishment_name', 'establishment');

const Types = {
    Product,
    Brand,
    Supplier,
    Establishment,
}

export default Types;

