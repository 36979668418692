import {Type} from "../model/ItemType";
import {Dispatch, ReactElement, RefObject, SetStateAction, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import MenuPanel from "./MenuPanel";
import ListPage from "../ListPage";

interface TabContentProps {
    type: Type;
    dispatchLoading: Dispatch<SetStateAction<boolean>>;
    toast: RefObject<Toast>;
}

export default function TabContent(props: TabContentProps): ReactElement {
    const [filter, setFilter] = useState<string>("");
    const data = useRef<{onRefresh: () => void}>();

    const onAfterUpload = () => {
        data.current?.onRefresh();
        setFilter("")
    }

    return (
        <>
            <MenuPanel type={props.type} onSearchClick={(e) => setFilter(e)} onAfterUpload={onAfterUpload}/>
            <ListPage type={props.type} dispatchLoading={props.dispatchLoading} toast={props.toast}
                      filter={filter}/>
        </>
    );
}
