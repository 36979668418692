import {Toast} from "primereact/toast";
import {Dispatch, RefObject, SetStateAction} from "react";
import LoginService from "./LoginService";
import LocalStorage from "../../../../core/localstorage/LocalStorage";

export default class LoginController {

    private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
    private readonly toast: RefObject<Toast>;

    constructor(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ) {
        this.dispatchLoading = dispatchLoading;
        this.toast = toast;
    }

    async login(email: string, password: string): Promise<boolean> {
        const token = await LoginService
            .auth({ email, password })
            .get(this.dispatchLoading, this.toast);

        if (token) {
            LocalStorage.setToken(token);
            LocalStorage.setEmail(email);
            return true;
        }
        return false;
    }

}
