import React, {Dispatch, ReactElement, RefObject, SetStateAction, useState} from "react";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import AdminService from "../service/AdminService";

interface PropsCreateUser {
    dispatchLoading: Dispatch<SetStateAction<boolean>>;
    toast: RefObject<Toast>;
}

export default function CreateUser (props: PropsCreateUser): ReactElement {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onCreate = () => {
        AdminService
            .create({email, password})
            .run(props.dispatchLoading, props.toast, `Usuário ${email} criado com sucesso!`)
            .then((_) => {
                setEmail('');
                setPassword('');
            });
    }

    return (
        <div className="surface-card p-4 shadow-2 border-round">
            <div className="text-center mb-5">
                <div className="text-900 text-3xl font-medium mb-3">Cadastrar</div>
            </div>
            <div>
                <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                <InputText value={email} onChange={(e) => setEmail(e.target.value)}
                           id="email" type="text" placeholder="Email address"
                           className="w-full mb-3"/>

                <label htmlFor="password"
                       className="block text-900 font-medium mb-2">Password</label>
                <InputText value={password} onChange={(e) => setPassword(e.target.value)}
                           type="password" placeholder="Password" className="w-full mb-3"/>

                <Button label="Cadastrar" icon="pi pi-user" className="w-full"
                        onClick={onCreate}/>
            </div>
        </div>
    );

}
