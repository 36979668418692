
import React, {ReactElement} from 'react';
import {Button} from "primereact/button";

interface PropsMenuItem {
    toQuarantine: () => void;
    toSafe: () => void;
}

export default function MenuMove(props: PropsMenuItem): ReactElement {
    return (
        <>
            <div className="flex flex-column">
                <div
                    className="flex align-items-center justify-content-center ml-1 mr-1">
                    <Button icon="pi pi-arrow-right"  className="w-2rem h-1rem mb-5 mt-5" severity="secondary" onClick={() => props.toSafe() }/>
                </div>
                <div
                    className="flex align-items-center justify-content-center">
                    <Button icon="pi pi-arrow-left"  className="w-2rem h-1rem mb-2" severity="secondary" onClick={() => props.toQuarantine() }/>
                </div>
            </div>
        </>
    );
}
